:root {
  --primary-blue: #0066FF;
  --primary-dark-blue: #0047B3;
  --secondary-blue: #00A3FF;
  --accent-blue: #E6F2FF;
  --dark-bg: #0A1A2F;
  --text-white: #FFFFFF;
  --text-light: rgba(255, 255, 255, 0.9);
  --text-muted: rgba(255, 255, 255, 0.7);
  --border-light: rgba(255, 255, 255, 0.1);
  --shadow-soft: 0 20px 80px rgba(0, 0, 0, 0.3);
  --shadow-button: 0 10px 25px rgba(0, 102, 255, 0.3);
}

.hero {
  position: relative;
  min-height: 90vh;
  background: linear-gradient(145deg, #001633 0%, #0A1A2F 70%);
  overflow: hidden;
  padding-top: 0;
  display: flex;
  align-items: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 20% 20%, rgba(0, 163, 255, 0.08) 0%, rgba(10, 26, 47, 0) 50%),
    radial-gradient(circle at 80% 80%, rgba(0, 102, 255, 0.08) 0%, rgba(10, 26, 47, 0) 50%);
  pointer-events: none;
  z-index: 1;
}

.hero-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 2;
  padding-top: 80px;
}

.hero-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(90vh - 80px);
  padding: 2rem 0;
}

.hero-left {
  max-width: 800px;
  text-align: center;
}

.hero-text {
  margin-bottom: 0;
}

.hero-text h1 {
  font-size: 4.5rem;
  font-weight: 800;
  color: var(--text-white);
  line-height: 1.1;
  margin-bottom: 2rem;
  letter-spacing: -0.03em;
}

.gradient-text {
  background: linear-gradient(135deg, var(--primary-blue) 0%, var(--secondary-blue) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.rotating-subheading {
  height: 3rem;
  margin-bottom: 2rem;
  position: relative;
}

.rotating-subheading h2 {
  font-size: 2rem;
  color: var(--text-light);
  font-weight: 600;
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.rotating-subheading h2.fade-in-out {
  opacity: 1;
  transform: translateY(0);
}

.hero-subtitle {
  font-size: 1.25rem;
  color: var(--text-muted);
  line-height: 1.6;
  margin: 0 auto 3rem;
  max-width: 600px;
}

.hero-cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.primary-cta {
  display: inline-flex;
  align-items: center;
  padding: 1.25rem 2.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-white);
  background: linear-gradient(135deg, var(--primary-blue) 0%, var(--secondary-blue) 100%);
  border-radius: 8px;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: var(--shadow-button);
}

.primary-cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 15px 30px rgba(0, 102, 255, 0.4);
}

.hero-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 2rem;
  background: rgba(0, 102, 255, 0.08);
  border-radius: 16px;
  border: 1px solid rgba(0, 102, 255, 0.2);
  margin-bottom: 1rem;
  box-shadow: var(--shadow-soft);
  backdrop-filter: blur(5px);
}

.stat-item {
  padding: 1rem;
  border-radius: 12px;
  position: relative;
  z-index: 1;
}

.stat-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(145deg, rgba(0, 102, 255, 0.05) 0%, rgba(0, 163, 255, 0.05) 100%);
  border-radius: 12px;
  z-index: -1;
}

.stat-value {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, var(--primary-blue) 0%, var(--secondary-blue) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  font-size: 0.875rem;
  color: var(--text-muted);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
}

.hero-right {
  width: 100%;
  position: relative;
}

.floating-shape {
  position: absolute;
  background: linear-gradient(135deg, var(--primary-blue) 0%, var(--secondary-blue) 100%);
  border-radius: 50%;
  filter: blur(60px);
  opacity: 0.15;
  z-index: 0;
}

.shape-1 {
  width: 400px;
  height: 400px;
  top: -100px;
  right: -150px;
}

.video-section {
  width: 100%;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: var(--shadow-soft);
  border: 1px solid var(--border-light);
  position: relative;
  z-index: 1;
}

.video-top-bar {
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.browser-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.dot-red {
  background-color: #FF5F56;
}

.dot-yellow {
  background-color: #FFBD2E;
}

.dot-green {
  background-color: #27C93F;
}

.video-thumbnail {
  width: 100%;
  aspect-ratio: 16/9;
  background: linear-gradient(145deg, rgba(0, 26, 51, 0.9) 0%, rgba(10, 26, 47, 0.9) 100%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.video-thumbnail::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(45deg, rgba(0, 102, 255, 0.05) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(0, 102, 255, 0.05) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(0, 102, 255, 0.05) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(0, 102, 255, 0.05) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  opacity: 0.05;
  z-index: 0;
}

.video-button {
  position: relative;
  background: transparent;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
}

.video-button-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-white);
  font-size: 1.125rem;
  font-weight: 500;
}

.play-icon {
  width: 24px;
  height: 24px;
  color: var(--primary-blue);
  animation: pulse 2s infinite;
}

.video-button-border {
  position: absolute;
  inset: 0;
  border: 2px solid var(--primary-blue);
  border-radius: 8px;
  animation: borderAnimation 4s linear infinite;
}

.video-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(0, 102, 255, 0.1);
  border-radius: 8px;
  transform: scale(0.95);
  transition: all 0.3s ease;
}

.video-button:hover::before {
  transform: scale(1);
  background: rgba(0, 102, 255, 0.2);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes borderAnimation {
  0% {
    clip-path: inset(0 0 98% 0);
  }
  25% {
    clip-path: inset(0 98% 0 0);
  }
  50% {
    clip-path: inset(98% 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 98%);
  }
  100% {
    clip-path: inset(0 0 98% 0);
  }
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  background: rgba(10, 26, 47, 0.95);
  backdrop-filter: blur(10px);
}

.video-wrapper {
  position: relative;
  width: 90%;
  max-width: 1200px;
  aspect-ratio: 16/9;
  background: #000;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wistia_responsive_padding {
  padding: 56.25% 0 0 0;
  position: relative;
}

.wistia_responsive_wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.wistia_embed {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}

.close-video-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1100;
}

.close-video-button:hover {
  background: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.1);
}

.close-video-button svg {
  width: 24px;
  height: 24px;
  stroke-width: 2.5;
}

wistia-player {
  width: 100%;
  height: 100%;
  display: block;
}

.trust-badges {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1.5rem;
}

.badge {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  background: rgba(0, 102, 255, 0.08);
  border: 1px solid rgba(0, 102, 255, 0.2);
  border-radius: 12px;
  backdrop-filter: blur(5px);
}

.badge-icon {
  font-size: 1.25rem;
  color: var(--primary-blue);
}

.badge-text {
  font-size: 0.9rem;
  color: var(--text-light);
  font-weight: 500;
  white-space: nowrap;
}

.primary-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2rem;
  background: var(--primary-blue);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-button);
}

.primary-button:hover {
  background: var(--primary-dark-blue);
  transform: translateY(-2px);
}

.secondary-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2rem;
  background: transparent;
  border: 1px solid rgba(0, 102, 255, 0.3);
  border-radius: 8px;
  color: var(--primary-blue);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.secondary-button:hover {
  background: rgba(0, 102, 255, 0.05);
  border-color: var(--primary-blue);
  transform: translateY(-2px);
}

.arrow-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.scroll-button,
.scroll-button:hover,
.scroll-icon {
  display: none;
}

@media (max-width: 1200px) {
  .hero-content {
    padding: 0 2rem;
  }

  .hero-text h1 {
    font-size: 4.5rem;
  }
  
  .hero-text h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 1024px) {
  .hero-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .hero-right {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .hero-left {
    align-items: center;
    text-align: center;
  }
  
  .hero-subtitle {
    margin-left: auto;
    margin-right: auto;
  }

  .trust-badges {
    justify-content: center;
  }
  
  .hero-stats {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .hero-content {
    padding: 0 1rem;
    padding-top: 80px;
  }

  .nav-logo span {
    font-size: 1.1rem;
  }

  .mobile-menu-button {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: rgba(10, 26, 47, 0.98);
    backdrop-filter: blur(10px);
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    transition: right 0.3s ease;
  }

  .nav-links.mobile-open {
    right: 0;
  }

  .nav-link {
    font-size: 1.5rem;
    text-align: center;
    padding: 1rem 0;
  }

  .nav-cta {
    margin-top: 1rem;
    padding: 1rem 2rem;
    width: 100%;
    justify-content: center;
  }

  .hero-text h1 {
    font-size: 3rem;
  }

  .rotating-subheading h2 {
    font-size: 1.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.125rem;
    padding: 0 1rem;
  }
  
  .primary-cta {
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  .hero-stats {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .trust-badges {
    flex-wrap: wrap;
    justify-content: center;
  }

  .hero-cta-container {
    width: 100%;
  }

  .video-button {
    width: 100%;
  }

  .video-button-content {
    justify-content: center;
    font-size: 1rem;
  }

  .video-wrapper {
    width: 95%;
  }
  
  .close-video-button {
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
  }

  .close-video-button svg {
    width: 20px;
    height: 20px;
  }

  .hero-grid {
    padding: 1.5rem 0;
  }
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(10, 26, 47, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1100;
  padding: 2rem;
}

.video-modal-content {
  position: relative;
  width: 90%;
  max-width: 1200px;
  aspect-ratio: 16/9;
  background: #000;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.5);
}

.close-video-button {
  position: fixed;
  top: 2rem;
  right: 2rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1200;
  padding: 0;
}

.close-video-button:hover {
  background: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.4);
  transform: scale(1.1);
}

.close-video-button svg {
  width: 28px;
  height: 28px;
  stroke: currentColor;
  stroke-width: 2;
}

@media (max-width: 768px) {
  .video-modal {
    padding: 1rem;
  }

  .video-modal-content {
    width: 95%;
  }

  .close-video-button {
    top: 1.5rem;
    right: 1.5rem;
    width: 40px;
    height: 40px;
  }

  .close-video-button svg {
    width: 24px;
    height: 24px;
  }
} 