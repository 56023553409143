.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  z-index: 1001;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #007bff, #00ff88);
  transition: width 0.1s ease;
} 