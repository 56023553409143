.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(10, 26, 47, 0.8);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar.scrolled {
  background: rgba(10, 26, 47, 0.95);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.nav-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.25rem;
}

.nav-logo {
  height: 40px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem;
  transition: all 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: white;
}

.nav-link:hover::after {
  width: 100%;
}

.nav-link.active {
  color: white;
}

.nav-link.active::after {
  width: 100%;
}

.primary-cta {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  color: white;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.primary-cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 102, 255, 0.3);
}

.mobile-menu-btn {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.mobile-menu-btn span {
  width: 100%;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
}

@media (max-width: 1024px) {
  .nav-links {
    gap: 1rem;
  }

  .nav-link {
    font-size: 0.9rem;
  }

  .primary-cta {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .mobile-menu-btn {
    display: flex;
    z-index: 100;
  }

  .mobile-menu-btn.open span:first-child {
    transform: translateY(9px) rotate(45deg);
  }

  .mobile-menu-btn.open span:nth-child(2) {
    opacity: 0;
  }

  .mobile-menu-btn.open span:last-child {
    transform: translateY(-9px) rotate(-45deg);
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    background: rgba(10, 26, 47, 0.98);
    backdrop-filter: blur(10px);
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    transition: all 0.3s ease;
  }

  .nav-links.open {
    right: 0;
  }

  .nav-link {
    font-size: 1.25rem;
    padding: 1rem;
    width: 100%;
    text-align: center;
  }

  .primary-cta {
    width: 100%;
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
  }
}

html {
  scroll-behavior: smooth;
} 