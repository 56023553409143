:root {
  --primary-blue: #0066FF;
  --primary-blue-dark: #0047B3;
  --text-primary: #2D3748;
  --text-secondary: #4A5568;
  --text-tertiary: #718096;
  --bg-light: #f8f9ff;
  --bg-white: #ffffff;
  --border-light: rgba(0, 102, 255, 0.1);
  --shadow-small: 0 4px 20px rgba(0, 0, 0, 0.04);
  --shadow-medium: 0 10px 30px rgba(0, 0, 0, 0.05);
  --shadow-large: 0 20px 50px rgba(0, 0, 0, 0.06);
  --gradient-blue: linear-gradient(135deg, #0066FF 0%, #0047B3 100%);
  --gradient-light: linear-gradient(135deg, rgba(0, 102, 255, 0.05) 0%, rgba(0, 71, 179, 0.08) 100%);
}

/* Reset & Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--text-primary);
  line-height: 1.5;
  background-color: var(--bg-white);
  overflow-x: hidden;
}

.App {
  overflow: hidden;
  position: relative;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: var(--text-primary);
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem;
}

/* Buttons */
.primary-button {
  background: var(--gradient-blue);
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0, 102, 255, 0.2);
}

.primary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 15px 30px rgba(0, 102, 255, 0.3);
}

.secondary-button {
  background: transparent;
  border: 2px solid var(--primary-blue);
  color: var(--primary-blue);
  padding: 0.9rem 1.8rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.secondary-button:hover {
  background: rgba(0, 102, 255, 0.1);
}

/* Gradient Text */
.gradient-text {
  background: var(--gradient-blue);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* Section Header */
.section-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
}

.section-header h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.section-header .gradient-text {
  background: var(--gradient-blue);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.section-header p {
  font-size: 1.25rem;
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Scroll Animation */
.scroll-animation {
  opacity: 0;
  transform: translateY(30px);
  transition: all 1s ease;
}

.scroll-animation.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Media Queries */
@media (max-width: 1200px) {
  h1 {
    font-size: 3rem;
  }
  
  h2 {
    font-size: 2.25rem;
  }
  
  .section-header h2 {
    font-size: 2.25rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.75rem;
  }
  
  .section-header h2 {
    font-size: 2rem;
  }
  
  .section-header p {
    font-size: 1.1rem;
  }
  
  .primary-button,
  .secondary-button {
    padding: 0.9rem 1.7rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.75rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
}

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.split-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1400px;
  margin: 4rem auto;
  padding: 0 2rem;
  align-items: stretch;
}

.split-section > div {
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.split-section > div > section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.split-section .timeline-container,
.split-section .features-grid {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

section {
  opacity: 0;
  animation: scaleIn 0.6s ease forwards;
}

@media (max-width: 1200px) {
  .split-section {
    grid-template-columns: 1fr;
    margin: 2rem;
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .split-section {
    margin: 1rem;
    padding: 0 1rem;
  }
}

/* Add smooth scrolling */
html {
  scroll-behavior: smooth;
}
