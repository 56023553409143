.footer {
  background: #061425;
  color: #FFFFFF;
  padding: 1rem 0;
  margin-top: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}

.footer-content p {
  color: #FFFFFF;
  font-size: 0.9rem;
}

.footer-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.footer-logo-img {
  width: 24px;
  height: 24px;
}

.footer-logo span {
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 500;
}

.footer-description {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.footer-right p {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 1rem;
    text-align: center;
  }

  .footer-left {
    flex-direction: column;
  }
} 