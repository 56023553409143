/* Base Section Styling */
.ecosystem-explanation-section {
  background-color: #080B16;
  color: white;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
}

.ecosystem-explanation-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(41, 98, 255, 0.1), transparent 70%);
  pointer-events: none;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.section-header {
  text-align: center;
  margin-bottom: 2rem;
}

.overline {
  display: inline-block;
  padding: 0.75rem 2rem;
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.overline:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.15);
}

.section-header h2 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0 0 1.5rem;
  background: linear-gradient(135deg, #FFFFFF 0%, #B7D4FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-header .subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #A3B8CC;
  max-width: 700px;
  margin: 0 auto;
}

/* Ecosystem Map */
.ecosystem-map {
  position: relative;
  width: 100%;
  height: 600px;
  margin: 2rem 0;
}

.central-hub {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.hub-pulse {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: rgba(75, 159, 255, 0.1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.hub-content {
  position: relative;
  width: 160px;
  height: 160px;
  background: linear-gradient(135deg, #2962FF 0%, #4B9FFF 100%);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 30px rgba(41, 98, 255, 0.3);
}

.hub-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 8px;
}

.hub-logo svg {
  width: 100%;
  height: 100%;
  stroke: white;
}

.hub-content span {
  font-size: 1.125rem;
  font-weight: 600;
  color: white;
}

.ecosystem-node {
  position: absolute;
  transform: translate(-50%, -50%);
}

.node-content {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  width: 160px;
}

.node-content:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-5px);
}

.node-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
}

.node-icon svg {
  width: 100%;
  height: 100%;
  stroke: #4B9FFF;
}

.node-content span {
  font-size: 1rem;
  color: white;
  font-weight: 500;
}

.node-connections {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
}

.connection-line {
  position: absolute;
  width: 200px;
  height: 2px;
  background: linear-gradient(90deg, rgba(75, 159, 255, 0.3) 0%, rgba(75, 159, 255, 0.1) 100%);
  transform-origin: left center;
}

/* Connection line positions for each node type */
.recruiters-node .connection-line.to-humbleco {
  width: 250px;
  transform: rotate(45deg);
}

.enterprise-node .connection-line.to-humbleco {
  width: 250px;
  transform: rotate(135deg);
}

.consultants-node .connection-line.to-humbleco {
  width: 180px;
  transform: rotate(30deg);
}

.executive_coaches-node .connection-line.to-humbleco {
  width: 180px;
  transform: rotate(150deg);
}

.pe_firms-node .connection-line.to-humbleco {
  width: 160px;
  transform: rotate(15deg);
}

.ma_advisors-node .connection-line.to-humbleco {
  width: 160px;
  transform: rotate(165deg);
}

.restructuring-node .connection-line.to-humbleco {
  width: 200px;
  transform: rotate(0deg);
}

.funds-node .connection-line.to-humbleco {
  width: 200px;
  transform: rotate(180deg);
}

.startup-node .connection-line.to-humbleco {
  width: 140px;
  transform: rotate(90deg);
}

/* Node to node connections */
.recruiters-node .connection-line.to-enterprise {
  width: 120px;
  transform: rotate(0deg);
}

.consultants-node .connection-line.to-enterprise {
  width: 130px;
  transform: rotate(-45deg);
}

.pe_firms-node .connection-line.to-ma_advisors {
  width: 100px;
  transform: rotate(0deg);
}

.funds-node .connection-line.to-startup {
  width: 110px;
  transform: rotate(-45deg);
}

.startup-node .connection-line.to-consultants {
  width: 120px;
  transform: rotate(135deg);
}

.connection-particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #4B9FFF;
  border-radius: 50%;
  animation: moveParticle 3s infinite linear;
  box-shadow: 0 0 10px rgba(75, 159, 255, 0.5);
}

@keyframes moveParticle {
  0% {
    left: 0;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

/* Hover effects for connections */
.ecosystem-node:hover .connection-line {
  background: linear-gradient(90deg, rgba(75, 159, 255, 0.6) 0%, rgba(75, 159, 255, 0.3) 100%);
}

.ecosystem-node:hover .connection-particle {
  box-shadow: 0 0 15px rgba(75, 159, 255, 0.8);
}

/* Pillars Grid */
.pillars-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2rem 0;
}

.pillar-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s ease;
}

.pillar-card:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-5px);
}

.pillar-number {
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #4B9FFF 0%, #2962FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  margin-bottom: 1rem;
}

.pillar-content h3 {
  font-size: 1.5rem;
  margin: 0 0 1rem;
  color: white;
}

.pillar-content p {
  color: #A3B8CC;
  line-height: 1.6;
  margin: 0;
}

.pillar-bullets,
.feature-bullets {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
  text-align: left;
}

.pillar-bullets li,
.feature-bullets li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.5;
}

.pillar-bullets li:last-child,
.feature-bullets li:last-child {
  margin-bottom: 0;
}

.pillar-bullets li::before,
.feature-bullets li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #00C853;
  font-weight: bold;
}

.pillar-card,
.feature-card {
  min-height: 280px;
  display: flex;
  flex-direction: column;
}

.pillar-content,
.feature-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pillar-content h3,
.feature-content h4 {
  margin-bottom: 0.5rem;
}

/* Metrics Section */
.metrics-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  margin: 4rem 0 4rem;
}

.metric-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  padding: 2.5rem;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.metric-card:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-5px);
  border-color: rgba(0, 200, 83, 0.2);
  box-shadow: 0 15px 30px rgba(0, 200, 83, 0.1);
}

.metric-value {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1;
  background: linear-gradient(135deg, #00C853 0%, #69F0AE 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.metric-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.1rem;
  line-height: 1.5;
  max-width: 200px;
  margin: 0 auto;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .ecosystem-map {
    height: 500px;
  }

  .pillars-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .hub-pulse {
    width: 160px;
    height: 160px;
  }

  .hub-content {
    width: 120px;
    height: 120px;
  }

  .hub-logo {
    width: 48px;
    height: 48px;
  }

  .node-content {
    width: 140px;
    padding: 1.25rem;
  }

  .node-icon {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .ecosystem-explanation-section {
    padding: 3rem 1.5rem;
  }

  .section-header h2 {
    font-size: 2.5rem;
  }

  .ecosystem-map {
    height: 400px;
  }

  .pillars-grid,
  .metrics-section {
    grid-template-columns: 1fr;
  }

  .node-content {
    padding: 1rem;
  }

  .node-icon {
    width: 32px;
    height: 32px;
  }

  .node-content span {
    font-size: 0.75rem;
  }

  .section-header {
    margin-bottom: 1.5rem;
  }

  .hub-pulse {
    width: 120px;
    height: 120px;
  }

  .hub-content {
    width: 100px;
    height: 100px;
  }

  .hub-logo {
    width: 36px;
    height: 36px;
  }

  .features-section {
    margin-top: 1.5rem;
  }

  .metrics-section {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin: 3rem 0;
  }

  .metric-card {
    padding: 2rem;
    min-height: 160px;
  }

  .metric-value {
    font-size: 3.5rem;
  }

  .metric-label {
    font-size: 1rem;
  }

  .pillar-bullets li,
  .feature-bullets li {
    font-size: 0.95rem;
    padding-left: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .pillar-card,
  .feature-card {
    min-height: 240px;
  }
}

@media (max-width: 480px) {
  .section-header h2 {
    font-size: 2rem;
  }

  .ecosystem-map {
    height: 300px;
  }

  .hub-content {
    width: 80px;
    height: 80px;
  }

  .hub-logo {
    width: 30px;
    height: 30px;
  }

  .node-content {
    width: 100px;
    padding: 0.875rem;
  }

  .node-icon {
    width: 28px;
    height: 28px;
  }
}

.features-section {
  margin-top: 2rem;
  width: 100%;
}

.features-heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #FFFFFF;
  position: relative;
}

.features-heading:after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  border-radius: 3px;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  width: 100%;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 102, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.feature-card:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  opacity: 0.8;
  transition: all 0.3s ease;
}

.feature-card:hover:after {
  width: 6px;
  opacity: 1;
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin-bottom: 1.5rem;
  color: #00A3FF;
  border-radius: 12px;
  background: rgba(0, 102, 255, 0.1);
  border: 1px solid rgba(0, 163, 255, 0.2);
  padding: 1rem;
}

.feature-icon svg {
  width: 100%;
  height: 100%;
}

.feature-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.feature-content h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #FFFFFF;
  margin: 0;
}

.feature-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

@media (max-width: 768px) {
  .features-section {
    margin-top: 1.5rem;
  }

  .features-heading {
    font-size: 2rem;
  }

  .feature-cards {
    grid-template-columns: 1fr;
  }
  
  .feature-card {
    padding: 1.5rem;
  }
  
  .feature-content h4 {
    font-size: 1.3rem;
  }
} 