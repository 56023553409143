.partners-section {
  padding: 4rem 0;
  background: #080B16;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.partners-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 20% 20%, rgba(0, 102, 255, 0.08) 0%, transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(0, 102, 255, 0.08) 0%, transparent 40%);
  pointer-events: none;
  z-index: 1;
}

.container {
  position: relative;
  z-index: 2;
}

.section-header {
  text-align: center;
  margin-bottom: 2.5rem;
}

.section-badge {
  display: inline-block;
  padding: 0.75rem 2rem;
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.section-badge:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.15);
}

.section-header h2 {
  font-size: 3rem;
  font-weight: 700;
  margin: 1rem 0;
}

.gradient-text {
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.section-subtitle {
  color: #A0A0A0;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.testimonial-carousel {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.testimonial-container {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 24px;
  padding: 2rem;
  margin: 1rem auto;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
  height: 320px;
}

.testimonial-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.impact-metric {
  text-align: center;
  margin-bottom: 1.5rem;
}

.metric-value {
  font-size: 3.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: block;
  margin-bottom: 0.25rem;
}

.metric-label {
  font-size: 1.2rem;
  color: #FFFFFF;
  display: block;
  margin-bottom: 0.25rem;
}

.metric-timeframe {
  font-size: 1rem;
  color: #A0A0A0;
  display: block;
}

.partner-info {
  text-align: center;
  margin-bottom: 1.5rem;
}

.partner-info h3 {
  font-size: 1.75rem;
  color: #FFFFFF;
  margin-bottom: 1rem;
}

.partner-description {
  font-size: 1.1rem;
  color: #A0A0A0;
  line-height: 1.6;
}

.success-indicator {
  margin-top: auto;
}

.indicator-line {
  position: relative;
  height: 2px;
  background: rgba(255, 255, 255, 0.1);
  margin: 1rem 0;
}

.indicator-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  transform-origin: left;
  animation: progress 2s ease-out forwards;
}

@keyframes progress {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

.indicator-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.indicator-dot.start {
  left: 0;
  background: #0066FF;
}

.indicator-dot.end {
  right: 0;
  transform: translate(50%, -50%);
  background: #00A3FF;
}

.timeline-labels {
  display: flex;
  justify-content: space-between;
  color: #A0A0A0;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.carousel-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;
}

.carousel-button:hover {
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  transform: scale(1.1);
}

.carousel-dots {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  margin-top: 1rem;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  width: 24px;
  border-radius: 12px;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
}

@media (max-width: 768px) {
  .partners-section {
    padding: 3rem 0;
  }

  .section-header h2 {
    font-size: 2.25rem;
  }

  .testimonial-container {
    padding: 1.5rem;
    height: 300px;
  }

  .testimonial-card {
    padding: 1.5rem;
  }

  .metric-value {
    font-size: 2.75rem;
  }

  .partner-info h3 {
    font-size: 1.5rem;
  }

  .partner-description {
    font-size: 1rem;
  }

  .carousel-button {
    width: 40px;
    height: 40px;
  }
} 