.pain-points-section {
  background: #080B16;
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.pain-points-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 20% 20%, rgba(0, 102, 255, 0.08) 0%, transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(0, 102, 255, 0.08) 0%, transparent 40%);
  pointer-events: none;
  z-index: 1;
}

.pain-points-section .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 2;
}

.pain-points-section .section-header {
  text-align: center;
  margin-bottom: 5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.section-badge {
  display: inline-block;
  padding: 0.75rem 2rem;
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.section-badge:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.15);
}

.pain-points-section .section-header h2 {
  font-size: 3.5rem;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.pain-points-section .highlight {
  color: #FF3A46;
  background: none;
  -webkit-text-fill-color: initial;
  position: relative;
  display: inline-block;
}

.pain-points-section .highlight::after {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 8px;
  background: rgba(255, 59, 70, 0.2);
  z-index: -1;
  transform: skewX(-15deg);
}

.pain-points-section .section-subtitle {
  font-size: 1.25rem;
  color: #A3B8CC;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.pain-points-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-bottom: 5rem;
}

.pain-point-card {
  background: rgba(255, 255, 255, 0.02);
  border-radius: 20px;
  padding: 2.5rem;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  height: 100%;
}

.pain-point-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(145deg, rgba(255, 59, 70, 0.05) 0%, rgba(255, 89, 89, 0.05) 100%);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.pain-point-card:hover {
  transform: translateY(-10px);
  border-color: rgba(255, 59, 70, 0.3);
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.4),
    0 0 20px rgba(255, 59, 70, 0.1);
}

.pain-point-card:hover::before {
  opacity: 1;
}

.pain-point-icon {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.75rem;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(255, 59, 70, 0.15) 0%, rgba(255, 89, 89, 0.15) 100%);
  color: #FF3A46;
  position: relative;
  transition: all 0.3s ease;
}

.pain-point-icon::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 22px;
  background: linear-gradient(135deg, rgba(255, 59, 70, 0.5), rgba(255, 89, 89, 0.5));
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.pain-point-card:hover .pain-point-icon::after {
  opacity: 0.2;
}

.pain-point-icon svg {
  width: 40px;
  height: 40px;
  stroke-width: 1.5;
  transition: transform 0.3s ease;
}

.pain-point-card:hover .pain-point-icon svg {
  transform: scale(1.1);
}

.pain-point-card h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1.5rem;
  line-height: 1.3;
  height: 2em;
  display: flex;
  align-items: center;
}

.pain-point-card p {
  font-size: 1.125rem;
  color: #A3B8CC;
  line-height: 1.7;
}

.pain-point-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.pain-point-list li {
  position: relative;
  padding-left: 1.5rem;
  color: #A3B8CC;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  opacity: 0.9;
  text-align: left;
}

.pain-point-list li:hover {
  opacity: 1;
  transform: translateX(5px);
  color: #ffffff;
}

.pain-point-list li:last-child {
  margin-bottom: 0;
}

.pain-point-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.85rem;
  width: 6px;
  height: 6px;
  background: #FF3A46;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 59, 70, 0.5);
}

.pain-point-list li:hover::before {
  transform: translateY(-50%) scale(1.5);
  background: #FF3A46;
}

.pain-points-cta {
  text-align: center;
  margin-top: 4rem;
  position: relative;
  padding: 4rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

.pain-points-cta::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  background: linear-gradient(145deg, rgba(255, 59, 70, 0.1), transparent);
  z-index: -1;
}

.pain-points-cta .pain-hook {
  font-size: 2.25rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1.5rem;
  line-height: 1.3;
}

.solution-text {
  font-size: 1.25rem;
  color: #A3B8CC;
  max-width: 800px;
  margin: 1rem auto 0;
  line-height: 1.6;
}

.pain-point-card:nth-child(2) h3 {
  font-size: 1.35rem;
}

@media (max-width: 1200px) {
  .pain-points-section .section-header h2 {
    font-size: 3rem;
  }
  
  .pain-points-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .pain-point-card {
    padding: 2.5rem;
  }
  
  .pain-point-card h3 {
    height: auto;
    min-height: 2em;
  }
}

@media (max-width: 768px) {
  .pain-points-section {
    padding: 6rem 0;
  }

  .pain-points-section .section-header h2 {
    font-size: 2.5rem;
  }

  .pain-points-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .pain-point-card {
    padding: 1.75rem;
  }
  
  .pain-points-cta {
    padding: 2.5rem;
  }
  
  .pain-points-cta .pain-hook {
    font-size: 1.75rem;
  }
  
  .solution-text {
    font-size: 1.125rem;
  }

  .pain-point-list li {
    font-size: 1rem;
    padding-left: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .pain-point-list li::before {
    width: 5px;
    height: 5px;
  }
  
  .section-badge {
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
    margin-bottom: 1.5rem;
  }

  .pain-point-icon {
    width: 55px;
    height: 55px;
    margin-bottom: 1.25rem;
  }
  
  .pain-point-card h3 {
    font-size: 1.35rem;
    margin-bottom: 1.25rem;
    height: auto;
    min-height: 0;
  }
} 