.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loader {
  width: 80px;
  height: 80px;
  animation: rotate 2s linear infinite;
}

.loader svg {
  width: 100%;
  height: 100%;
}

.loader circle {
  fill: none;
  stroke: #007bff;
  stroke-width: 4;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 200;
  }
} 