/* Create a new file for shared styles */
.primary-cta {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 123, 255, 0.3);
}

.primary-cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 123, 255, 0.4);
}

/* Add consistent heading sizes */
h1 {
  font-size: 3.5rem; /* Currently varies from 4.5rem to 3rem */
}

h2 {
  font-size: 2.5rem; /* Currently varies from 3.5rem to 2.5rem */
}

h3 {
  font-size: 2rem; /* Currently varies from 2.5rem to 1.8rem */
}

/* Add consistent section padding */
section {
  padding: 6rem 0; /* Currently varies from 8rem to 4rem */
}

/* Consistent container padding */
.container {
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

/* Consistent grid gaps */
.grid {
  gap: 2rem; /* Currently varies from 2.5rem to 1.5rem */
}

/* Consistent card styling */
.card {
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  /* Currently varies in padding and border-radius */
}

@media (max-width: 768px) {
  section {
    padding: 4rem 0;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  .container {
    padding: 0 1.5rem;
  }
}

.cta-section {
  position: relative;
  background: #080B16;
  color: #FFFFFF;
  padding: 3rem 0;
  overflow: hidden;
}

.cta-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 20% 20%, rgba(0, 163, 255, 0.08) 0%, rgba(10, 26, 47, 0) 50%),
    radial-gradient(circle at 80% 80%, rgba(0, 102, 255, 0.08) 0%, rgba(10, 26, 47, 0) 50%);
  pointer-events: none;
  z-index: 1;
}

.section-badge {
  display: inline-block;
  padding: 0.75rem 2rem;
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.section-badge:hover {
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.15);
}

.cta-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.location-container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  padding: 1.25rem 2.5rem;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin: 1rem 0;
}

.location-icon-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.location-icon {
  color: #00A3FF;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.west-coast {
  color: #4CC9F0;
}

.east-coast {
  color: #4361EE;
}

.arrow-between {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.25rem;
}

.location-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}

.location-text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.location-subtext {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.95rem;
  font-weight: 500;
  letter-spacing: 0.01em;
}

.cta-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.cta-heading {
  font-size: 3.5rem;
  font-weight: 700;
  margin: 1rem 0;
  color: #FFFFFF;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1.25rem 2.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #FFFFFF;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 10px 25px rgba(0, 102, 255, 0.3);
  position: relative;
  z-index: 1;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #00A3FF, #0066FF, #00A3FF);
  border-radius: 10px;
  z-index: -1;
  animation: borderGlow 3s ease-in-out infinite;
  opacity: 0.7;
}

@keyframes borderGlow {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.01);
  }
  100% {
    opacity: 0.7;
    transform: scale(1);
  }
}

.cta-button:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 102, 255, 0.4);
}

.cta-button:hover::before {
  animation: borderGlow 1.5s ease-in-out infinite;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.cta-button:hover .arrow-icon {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .cta-section {
    padding: 4rem 0;
  }

  .cta-heading {
    font-size: 2.25rem;
  }

  .cta-button {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }

  .location-text {
    font-size: 1.1rem;
  }

  .location-subtext {
    font-size: 0.9rem;
  }

  .location-icon {
    font-size: 1.5rem;
  }
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.linkedin-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1.25rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #FFFFFF;
  background: rgba(10, 102, 194, 0.9);
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.linkedin-button:hover {
  transform: translateY(-2px);
  background: rgb(10, 102, 194);
  box-shadow: 0 8px 20px rgba(10, 102, 194, 0.3);
}

.linkedin-icon {
  font-size: 1.4rem;
}

@media (max-width: 768px) {
  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }

  .linkedin-button {
    width: 100%;
    justify-content: center;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }

  .cta-button {
    width: 100%;
    justify-content: center;
  }
}

.testimonial-section {
  margin-top: 4rem;
  width: 100%;
}

.testimonial-heading {
  font-size: 2rem;
  color: #FFFFFF;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(135deg, #0066FF 0%, #00A3FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonial-carousel {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: 1000px;
  margin: 0 auto;
}

.testimonial-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.testimonial-card {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.testimonial-card.active {
  opacity: 1;
  transform: translateX(0);
}

.video-thumbnail {
  position: relative;
  width: 100%;
  height: 225px;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(0, 102, 255, 0.9);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.play-button:hover {
  background: rgb(0, 102, 255);
  transform: translate(-50%, -50%) scale(1.1);
}

.testimonial-content {
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-quote {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.testimonial-author {
  text-align: left;
}

.testimonial-author h4 {
  color: #FFFFFF;
  font-size: 1.2rem;
  margin: 0 0 0.25rem 0;
}

.testimonial-author p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-size: 0.9rem;
}

.company-name {
  color: #00A3FF !important;
  font-weight: 500;
}

.carousel-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;
}

.carousel-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  background: #00A3FF;
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .testimonial-section {
    margin-top: 3rem;
  }

  .testimonial-heading {
    font-size: 1.75rem;
  }

  .testimonial-container {
    height: 450px;
  }

  .testimonial-quote {
    font-size: 1rem;
  }

  .carousel-button {
    width: 36px;
    height: 36px;
  }

  .video-thumbnail {
    height: 200px;
  }

  .play-button {
    width: 50px;
    height: 50px;
    font-size: 1.25rem;
  }
}